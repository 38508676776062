import Home from "components/Home/Home";
import Dashboard from "components/Dashboard/Dashboard";
import NotFound from "components/NotFound/NotFound";
import Documents from "components/Documents/Documents";
import LearningCenter from "components/LearningCenter/LearningCenter";
import ChangeAllocations from "components/ChangeAllocations/ChangeAllocations";
import TakeAnnuityOutOfPlan from "components/TakeAnnuityOutOfPlan/TakeAnnuityOutOfPlan";
import StartIncome from "components/StartIncome/StartIncome";
import SetupAccount from "components/SetupAccount/SetupAccount";
import Notifications from "components/Notifications/Notifications";
import AddUpdateBeneficiary from "components/Beneficiary/AddUpdateBeneficiary";
import TestPage from "components/Test/TestPage";

export default Object.freeze({
  home: {
    component: <Home />,
    path: "/",
    isPrivate: false,
  },
  dashboard: {
    component: <Dashboard />,
    path: "/dashboard",
    isPrivate: true,
    layout: "MultiPageLayout",
  },
  documents: {
    component: <Documents />,
    path: "/documents",
    isPrivate: true,
    layout: "MultiPageLayout",
  },
  learningCenter: {
    component: <LearningCenter />,
    path: "/learning-center",
    isPrivate: true,
    layout: "MultiPageLayout",
  },
  changeAllocations: {
    component: <ChangeAllocations />,
    path: "/change-allocations/:id",
    isPrivate: true,
    layout: "SinglePageLayout",
  },
  takeAnnuityOutOfPlan: {
    component: <TakeAnnuityOutOfPlan />,
    path: "/take-annuity-out-of-plan/:id",
    isPrivate: true,
    layout: "SinglePageLayout",
  },
  startIncomeHome: {
    component: <StartIncome />,
    path: "/start-income",
    isPrivate: true,
    layout: "SinglePageLayout",
  },
  startIncomeHomeChild: {
    component: <StartIncome />,
    path: "/start-income/:id",
    isPrivate: true,
    layout: "SinglePageLayout",
  },
  setupAccount: {
    component: <SetupAccount />,
    path: "/setup-account",
    isPrivate: true,
    layout: "SinglePageLayout",
  },
  notifications: {
    component: <Notifications />,
    path: "/notifications",
    isPrivate: true,
    layout: "SinglePageLayout",
  },
  addUpdateBeneficiary: {
    component: <AddUpdateBeneficiary />,
    path: "/add-update-beneficiary",
    isPrivate: true,
    layout: "SinglePageLayout",
  },
  notFound: {
    component: <NotFound />,
    path: "*",
    isPrivate: false,
  },
  testPage: {
    component: <TestPage />,
    path: "/test-page",
    isPrivate: false,
  },
  learningCenterPublic: {
    component: <LearningCenter />,
    path: "/learning-page",
    isPrivate: false,
    layout: "MultiPageLayout",
  }
});
