import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "routes/PrivateRoute";
import PublicRoute from "routes/PublicRoute.js";
import routesList from "routes/RouteComponent";
import { Tenants } from "utils/Tenants";
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize(sessionStorage.getItem("analyticsId"));
  const tenantName = window.location.pathname.split("/")[1];
  const routeKeys = [];
  for (const route in routesList) {
    routeKeys.push(route);
  }
  if (Tenants.indexOf(tenantName) === -1) {
    return <h1 className="text-center">Client Not Found</h1>;
  } else {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL || `${tenantName}`}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {routeKeys.map((e) => {
              if (routesList[e].isPrivate) {
                return (
                  <Route
                    key={`${routesList[e].path}`}
                    path={`${routesList[e].path}`}
                    element={
                      <PrivateRoute layout={routesList[e].layout}>
                        {routesList[e].component}
                      </PrivateRoute>
                    }
                  />
                );
              } else {
                return (
                  <Route
                    key={`${routesList[e].path}`}
                    path={`${routesList[e].path}`}
                    element={
                      <PublicRoute layout={routesList[e].layout}>
                        {routesList[e].component}
                      </PublicRoute>
                    }
                  />
                );
              }
            })}
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
